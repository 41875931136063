/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
// countUp
// import CountUp from "react-countup";
// intersection observer hook
import { useInView } from "react-intersection-observer";
// motion
import { motion } from "framer-motion";
// variant
import { fadeIn } from "../variants";

const About = () => {
  const [ref] = useInView({
    threshold: 0.6,
  });
  return (
    <section className="section" id="about" ref={ref}>
      <div className="container bg-site2 bg-contain bg-no-repeat mx-auto">
        <div className="flex flex-col lg:flex-row gap-y-10 lg:items-center lg:gap-x-20 lg:gap-y-0">
          {/* img */}
          <div>
            <motion.div
              variants={fadeIn("right", 0.2)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.3 }}
              className="flex bg-about w-1/3 bg-contain rounded-full border border-l-slate-600 bg-no-repeat lg:w-[100px] lg:h-[300px] h-[300px] mix-blend-lighten bg-top"
            ></motion.div>
          </div>
          {/* text */}
          <motion.div
            variants={fadeIn("left", 0.3)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.3}}
          >
            <div className="flex-1 bg-[#09152b] bg-opacity-80 p-4 mt-[-100px]">
              <h2 className="h2 font-bold text-[#a05306]">About Me</h2>
              <h3 className="h3 font-bold mt-0 text-gray-400 text-sm">
                Technologist|| Web Developer| cybersecurity Analyst|| living in Nigeria
              </h3>
              <p className="mb-4 text-sm text-white tracking-wide text-justify leading-7 font-light">
                I am Israel Oshagara, a web developer and
                cybersecurity analyst with a strong passion for blending creativity and
                security in the realm of technology, across the Globe. I thrive on building dynamic, user-friendly websites
                and applications while ensuring that the solutions I develop are
                safeguarded against modern-day cyber threats.
              </p>
            </div>
            {/* stats
            <div className="flex gap-x-6 lg:gap-x-10 mb-12">
              <div>
                <div className="text-[40px] font-tertiary text-gradient mb-2">
                  <CountUp start={0} end={13} duration={3} />
                </div>
                <div className="font-primary text-sm tracking-[2px]">
                  Years of <br />
                  exprience
                </div>
              </div>
              <div>
                <div className="text-[40px] font-tertiary text-gradient mb-2">
                  <CountUp start={0} end={15} duration={3} />
                  k+
                </div>
                <div className="font-primary text-sm tracking-[2px]">
                  Projects <br />
                  Completed
                </div>
              </div>
              <div>
                <div className="text-[40px] font-tertiary text-gradient mb-2">
                  <CountUp start={0} end={13} duration={3} />
                  k+
                </div>
                <div className="font-primary text-sm tracking-[2px]">
                  Satisfied <br />
                  Clients
                </div>
              </div>
            </div> */}
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default About;

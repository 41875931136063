import React from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Img1 from "../assets/erental.png"
import { motion } from 'framer-motion';
import { fadeIn } from '../variants';

const Work = () => {
  return (
    <div id='services' className='bg-[#f7f8fc] md:text-sm'>
      <div className='pt-28 px-4 container mx-auto'>
        <motion.div 
        variants={fadeIn('up', 0.2)}
        initial="hidden"
        whileInView={"show"}
        viewport={{once: false, amount:0.2}}
        className='text-center space-y-5'>
        <h2 className='text-2xl font-secondary font-bold text-herobg'>Projects</h2>
        <p className='md:w-1/2 mx-auto text-sm'>what I have Achieved in the last 3years of experience.</p>
        </motion.div>
        {/* Service categories */}
        <div className='py-12 md:w-4/5 mx-auto'>
        <Tabs>
          <TabList 
          className="flex flex-wrap gap-1 text-xs items-center justify-center md:gap-8 md:text-sm">
            <Tab>e-rental website</Tab>
            <Tab>Deepminds website</Tab>
            <Tab>Portfolio website</Tab>
            <Tab>Deepminds</Tab>
          </TabList>

          <TabPanel>
          <motion.div 
            variants={fadeIn('up', 0.2)}
            initial="hidden"
            whileInView={"show"}
            viewport={{once: false, amount:0.2}}
            className='flex flex-col md:flex-row gap-8 mt-8'>
              <div className='md:w-1/2 bg-white rounded-lg p-12 font-secondary'>
                <h3 className='text-3xl font-normal text-primary mb-4'>Car rental Services</h3>
                <p className='mb-8 font-sm text-justify'>This project is to redefine the car rental experience by offering a diverse fleet of vehicles that cater to various preferences and requirements.</p>
                <h4 className='text-xl mb-4 font-medium text-black'>Technologies Used</h4>
                <ul className='list-disc list-inside space-y-3'>
                <li>HTML/CSS/JS</li>
                  <li>ReactJs</li>
                  <li>Bootsrap</li>
                  <li>REST API</li>
                </ul>
              </div>
              <div className='w-full md:w-1/2'>
                <img src={Img1} alt="" className='w-full h-auto rounded-2xl' />
              </div>
            </motion.div>
          </TabPanel>

          <TabPanel>
            <motion.div 
            variants={fadeIn('up', 0.2)}
            initial="hidden"
            whileInView={"show"}
            viewport={{once: false, amount:0.2}}
            className='flex flex-col md:flex-row gap-8 mt-8'>
              <div className='md:w-1/2 bg-white rounded-lg p-12 font-secondary'>
                <h3 className='text-3xl font-normal text-primary mb-4'>Car rental Services</h3>
                <p className='mb-8 font-sm text-justify'>This project is to redefine the car rental experience by offering a diverse fleet of vehicles that cater to various preferences and requirements.</p>
                <h4 className='text-xl mb-4 font-medium text-black'>Technologies Used</h4>
                <ul className='list-disc list-inside space-y-3'>
                <li>HTML/CSS/JS</li>
                  <li>ReactJs</li>
                  <li>Bootsrap</li>
                  <li>REST API</li>
                </ul>
              </div>
              <div className='w-full md:w-1/2'>
                <img src={Img1} alt="" className='w-full h-auto rounded-2xl' />
              </div>
            </motion.div>
          </TabPanel>

          <TabPanel>
          <motion.div 
            variants={fadeIn('up', 0.2)}
            initial="hidden"
            whileInView={"show"}
            viewport={{once: false, amount:0.2}}
            className='flex flex-col md:flex-row gap-8 mt-8'>
              <div className='md:w-1/2 bg-white rounded-lg p-12 font-secondary'>
                <h3 className='text-3xl font-normal text-primary mb-4'>Car rental Services</h3>
                <p className='mb-8 font-sm text-justify'>This project is to redefine the car rental experience by offering a diverse fleet of vehicles that cater to various preferences and requirements.</p>
                <h4 className='text-xl mb-4 font-medium text-black'>Technologies Used</h4>
                <ul className='list-disc list-inside space-y-3'>
                <li>HTML/CSS/JS</li>
                  <li>ReactJs</li>
                  <li>Bootsrap</li>
                  <li>REST API</li>
                </ul>
              </div>
              <div className='w-full md:w-1/2'>
                <img src={Img1} alt="" className='w-full h-auto rounded-2xl' />
              </div>
            </motion.div>
          </TabPanel>

          <TabPanel>
          <motion.div 
            variants={fadeIn('up', 0.2)}
            initial="hidden"
            whileInView={"show"}
            viewport={{once: false, amount:0.2}}
            className='flex flex-col md:flex-row gap-8 mt-8'>
              <div className='md:w-1/2 bg-white rounded-lg p-12 font-secondary'>
                <h3 className='text-3xl font-normal text-primary mb-4'>Car rental Services</h3>
                <p className='mb-8 font-sm text-justify'>This project is to redefine the car rental experience by offering a diverse fleet of vehicles that cater to various preferences and requirements.</p>
                <h4 className='text-xl mb-4 font-medium text-black'>Technologies Used</h4>
                <ul className='list-disc list-inside space-y-3'>
                <li>HTML/CSS/JS</li>
                  <li>ReactJs</li>
                  <li>Bootsrap</li>
                  <li>REST API</li>
                </ul>
              </div>
              <div className='w-full md:w-1/2'>
                <img src={Img1} alt="" className='w-full h-auto rounded-2xl' />
              </div>
            </motion.div>
          </TabPanel>

        </Tabs>
        </div>
      </div>
    </div>
  )
};

export default Work;